<template>
  <div>
    <div v-if="showSidebarLayout()" class="flex h-screen">
      <div v-bind:class="sidebarOpen ? 'block' : 'hidden'" @click="toggleSidebar()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
      <SidebarMenu />
      <div class="flex-1 flex flex-col overflow-hidden">
        <HeaderMenu />
        <main class="flex-1 overflow-x-hidden overflow-y-auto">
          <div class="container mx-auto px-2 py-2 lg:px-6 lg:py-8">
            <router-view />
          </div>
        </main>
      </div>
      <ChangePasswordModal />
      <OrderAlarmModalReseller ref="resellerOrderAlarm" />
      <VerifiedBrowsers />
    </div>
    <div v-else class="min-h-screen">
      <router-view />
    </div>
  </div>
</template>

<script>
import SidebarMenu from "@/components/layouts/sidebar_menu";
import HeaderMenu from "@/components/layouts/header_menu";
import ChangePasswordModal from "@/components/modal/change_password";
import OrderAlarmModalReseller from "@/components/modal/reseller_order_alarm";
import VerifiedBrowsers from "@/components/modal/verified_browsers";

export default {
  name: "Layout",
  props: {
    pageTitle: {
      type: String,
    },
  },
  components: {
    SidebarMenu,
    HeaderMenu,
    ChangePasswordModal,
    OrderAlarmModalReseller,
    VerifiedBrowsers,
  },
  data() {
    return {
      user: this.$store.state.user,
      page_title: this.pageTitle,
    };
  },
  methods: {
    showSidebarLayout() {
      return this.$route.path != "/" && this.$route.path != "/login" && this.$route.name != "trackAlarm" && this.$route.name != "autoProvlamStandalone" && this.$route.name != "alarm_center_track" && this.$route.name != "forgot_password" && this.$route.name != "reset_password" && this.$route.name != "change_password_first_login" && this.$route.name != "securdesktop";
    },

    toggleSidebar() {
      this.$store.commit("SET_SIDEBAR_OPEN", !this.sidebarOpen);
    },
  },
  computed: {
    sidebarOpen() {
      return this.$store.getters.getSidebarOpen;
    },
  },
  mounted() {},
};
</script>
