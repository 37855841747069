<template>
  <div>
    <div v-show="!showOTPVerification">
      <ValidationObserver ref="deviceLogin">
        <form class="flex flex-col">
          <div class="mb-8 rounded">
            <BaseInput v-model="phone" type="text" :field_name="$t('device_login_page.mobile_number')" rules="required" />
          </div>
          <div class="mb-8 rounded">
            <BaseInput v-model="password" type="password" :field_name="$t('device_login_page.password')" rules="required" />
          </div>
          <div class="clearfix">
            <div class="float-right">
              <button class="btn-green px-2 py-3 text-sm" @click.prevent="login()">
                <span class="mr-1">{{ $t("device_login_page.signin") }}</span> <BaseIcon icon="user" />
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>
export default {
  name: "DeviceLoginForm",
  data() {
    return {
      showOTPVerification: false,
      phone: "",
      password: "",
      otp: "",
      fingerprint: null,
    };
  },

  methods: {
    login(resendOTP = false) {
      this.$refs.deviceLogin.validate().then((success) => {
        if (!success) {
          return;
        }

        // if user click resend otp to reset otp field and generate new otp
        if (resendOTP) this.otp = "";

        let data = {
          phone: this.phone.toLowerCase(),
          password: this.password,
          fingerprint: this.fingerprint,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/login-device`, data)
          .then((response) => {
            if (response.status === 200) {
              this.storeAndNavigateUser(response);
            }
          })
          .catch((error) => {
            let error_message = "Användarnamnet eller lösenordet är felaktigt, försök igen";
            if (error.response && error.response.status == 429) error_message = "För många inloggningsförsök, försök efter en stund";
            this.handleError(error, error_message, "top-center");
          });
      });
    },

    storeAndNavigateUser(response) {
      let user = response.data.data;
      //let firstLogin = user.firstLogin;

      this.$store.dispatch("login", {
        token: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userObj: {
          firstname: user.firstname,
          lastname: user.lastname,
          username: user.firstname,
          role: user.role,
          customers_id: user.customers_id,
          units_id: user.units_id,
          alarm_center_id: user.alarm_center_id,
          reseller_id: user.reseller_id,
          seller_id: user.seller_id,
          tech_id: user.tech_id,
          reseller_type: user.reseller_type,
          reseller_alarm_center: user.reseller_alarm_center,
          reseller_special_access: user.reseller_special_access,
        },
        addons: user.addons,
      });

      let deviceData = {
        device: response.data.device,
        gps: response.data.gps,
        units: response.data.units,
        customers: response.data.customers
      };

      this.$store.dispatch("autoProvlarm", deviceData);

      this.axios.defaults.headers.common["X-Access-Token"] = response.data.accessToken;
      var _this = this
      setTimeout(function () {
        _this.$router.push("/auto-provlarm").catch(() => {});
      }, 100)
    },

    resetForm() {
      this.username = "";
      this.password = "";
      this.otp = "";
      this.$refs.deviceLogin.reset();
      this.$refs.formOTP.reset();
    },

    async getFingerprint() {
      const fp = await this.fpPromise;
      const result = await fp.get();
      // eslint-disable-next-line
      const { screenFrame, ...components } = result.components;
      const visitorId = this.FingerprintJS.hashComponents(components);

      this.fingerprint = {
        visitorId: visitorId,
        browser: result.components.vendorFlavors.value[0],
        platform: result.components.platform.value,
        userAgent: navigator.userAgent,
      };
    },
  },

  created() {
    this.getFingerprint();
  },
};
</script>
