<template>
  <div>
    <!-- Render Tab Headers -->
    <div class="tabs">
      <div v-for="(tab, index) in tabs" :key="index" :class="['tab', { active: selectedTab === tab.title }]" @click="selectedTab = tab.title">
        {{ tab.title }}
      </div>
    </div>

    <!-- Render Active Tab Content -->
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      tabs: [],
      selectedTab: null,
    };
  },
  mounted() {
    this.tabs = this.$children;
    if (this.tabs.length) {
      this.selectedTab = this.tabs[0].title;
    }
  },
  watch: {
    "$i18n.locale"() {
      setTimeout(() => {
        this.tabs = this.$children;
        if (this.tabs.length > 0) {
          this.selectedTab = this.tabs[0].title;
        }
      })

    },
  },
};
</script>
