<template>
  <div class="login">
    <div class="fixed top-0 right-0 p-4">
      <LocaleSwitcher v-on:change="$emit('localeChange', $event)" />
    </div>
    <div class="min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white xl:w-1/3 p-8 rounded-lg shadow-xl">
        <div>
          <router-link to="/">
            <img class="logo w-40 mx-auto" :src="require('@/assets/logos/logo_horizontal.png')" />
          </router-link>
        </div>
        <div class="mt-8">
           <Tabs>
            <Tab :title="$t('login_page.member_login')">
              <LoginForm class="mt-10"></LoginForm>
            </Tab>
            <Tab :title="$t('login_page.device_login')">
              <DeviceLoginForm class="mt-10"></DeviceLoginForm>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/login_form";
import DeviceLoginForm from "@/components/member_login_form";
import LocaleSwitcher from "@/components/locale_switcher";
import Tabs from "@/components/base/_tabs";
import Tab from "@/components/base/_tab";

export default {
  name: "Login",
  title() {
    return `Login | SecurCloud`;
  },
  components: {
    LoginForm,
    DeviceLoginForm,
    LocaleSwitcher,
    Tabs,
    Tab
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
};
</script>
<style>
.tabs {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ddd;
}
.tab {
  padding: 10px 60px;
  cursor: pointer;
}
.tab.active {
  font-weight: bold;
  border-bottom: 2px solid #1a8de9;
}
.tab-content {
  padding: 20px;
}
</style>