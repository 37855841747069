<template>
  <!-- Show content if this tab is selected -->
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    isActive() {
      // Return true if this tab is the active one
      return this.$parent.selectedTab === this.title;
    },
  },
};
</script>
