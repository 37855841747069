import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/login.vue";
import store from "@/store";

Vue.use(VueRouter);

function getId() {
  if (store.state.user.role == "superuser") return "-";
  else if (store.state.user.role == "user") return store.state.user.customers_id.length > 0 ? store.state.user.customers_id[0] : "-";
  else if (store.state.user.role == "securdesktop") return store.state.user.customers_id.length > 0 ? store.state.user.customers_id[0] : "-";
  else if (store.state.user.role == "alarm_center") return store.state.user.alarm_center_id;
  else if (store.state.user.role == "reseller") return store.state.user.reseller_id;
  else if (store.state.user.role == "seller") return store.state.user.seller_id;
  else if (store.state.user.role == "tech") return store.state.user.tech_id;
  else return "";
}

const routes = [
  {
    path: "/",
    name: "home",
    redirect: () => {
      // eslint-disable-line
      if (store.getters.isLoggedIn && store.state.user.role == "superuser") return { name: "dashboard", params: { id: getId() } };
      if (store.getters.isLoggedIn && store.state.user.role == "user") return { name: "dashboard", params: { id: getId() } };
      if (store.getters.isLoggedIn && store.state.user.role == "securdesktop") return { name: "securdesktop", params: { id: getId() } };
      else if (store.getters.isLoggedIn && store.state.user.role == "admin") return "/admin";
      else if (store.getters.isLoggedIn && store.state.user.role == "alarm_center") return { name: "alarm_center_dashboard", params: { id: getId() } };
      else if (store.getters.isLoggedIn && store.state.user.role == "reseller") return { name: "reseller_dashboard", params: { id: getId() } };
      else if (store.getters.isLoggedIn && store.state.user.role == "seller") return { name: "seller_dashboard", params: { id: getId() } };
      else if (store.getters.isLoggedIn && store.state.user.role == "tech") return { name: "tech_dashboard", params: { id: getId() } };
      else return "/login";
    },
    meta: {
      authRequired: false,
      roles: [],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      authRequired: false,
      roles: [],
    },
    beforeEnter: (to, from, next) => {
      // eslint-disable-line
      if (store.getters.isLoggedIn) next("/");
      else next();
    },
  },
  {
    path: "/startsida/:id",
    name: "dashboard",
    component: function() {
      return import("../views/dashboard/customer_unit.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user"],
    },
  },
  {
    path: "/securdesktop",
    name: "securdesktop",
    component: function() {
      return import("../views/dashboard/securdesktop.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "securdesktop"],
    },
  },
  {
    path: "/larm/:type/:customer_id/:unit_id/:id/:alarmId?",
    name: "alarms",
    component: function() {
      return import("../views/alarms.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/verksamheter/:type/:customer_id/:id",
    name: "units",
    component: function() {
      return import("../views/units.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/gps-unit/:type/:id/:typeId/:alarmId?",
    name: "gpsUnit",
    component: function() {
      return import("../views/securtrack/unit.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/gps-kund/:id",
    name: "gpsCustomer",
    component: function() {
      return import("../views/securtrack/customer.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/asset-tracker/:imei",
    name: "asset_tracker_alarm",
    component: function() {
      return import("../views/asset_tracker/asset_tracker_alarm.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/ronderingsbevakning/:type/:id",
    name: "rounding_surveillance",
    component: function() {
      return import("../views/securtool/customer_unit.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/alarm-reminder/customer/:customer_id",
    name: "alarm_reminder_customer",
    component: function() {
      return import("../views/alarm_test_reminder/customer.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/alarm-reminder/unit/:unit_id",
    name: "alarm_reminder_unit",
    component: function() {
      return import("../views/alarm_test_reminder/unit.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/trip-report/:type/:id",
    name: "trip_report_customer",
    component: function() {
      return import("../views/trip_report/trip_report.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/policy-routine",
    name: "policy_routines",
    component: function() {
      return import("../views/policy-and-routines/index.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/policy-routine/p/:id",
    name: "policy_post",
    component: function() {
      return import("../views/policy-and-routines/policy_post.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller"],
    },
  },
  {
    path: "/alarm-center/dashboard/:id",
    name: "alarm_center_dashboard",
    component: function() {
      return import("../views/dashboard/alarm_center.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "alarm_center"],
    },
  },
  {
    path: "/alarm-center/:id",
    name: "alarm_center_alarms",
    component: function() {
      return import("../views/alarm_center_alarms.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "alarm_center"],
    },
  },
  {
    path: "/alarm-center/securtrack/:id/:alarm_id?",
    name: "alarm_center_track",
    component: function() {
      return import("../views/securtrack/alarm_center.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "alarm_center", "reseller"],
    },
  },
  {
    path: "/alarm-center/securtool/:id",
    name: "alarm_center_securtool",
    component: function() {
      return import("../views/securtool/alarm_center.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "alarm_center", "reseller"],
    },
  },
  {
    path: "/alarm-center/email-log/:type/:id?",
    name: "alarm_center_email_log",
    component: function() {
      return import("../views/alarm_center_email_log.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "reseller", "seller"],
    },
  },
  {
    path: "/alarm-center/alarm-via-securcloud/:id",
    name: "alarm_center_report_log",
    component: function() {
      return import("../views/alarm_center_report_log.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller", "alarm_center"],
    },
  },
  {
    path: "/reseller/:id",
    name: "reseller_dashboard",
    component: function() {
      return import("../views/dashboard/reseller.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "reseller"],
    },
  },
  {
    path: "/seller/:id",
    name: "seller_dashboard",
    component: function() {
      return import("../views/dashboard/seller.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/profile/update/:id",
    name: "staff_profile",
    component: function() {
      return import("../views/staff_profile.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller", "tech"],
    },
  },
  {
    path: "/sales/:type/:id",
    name: "sales",
    component: function() {
      return import("../views/sales.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/company-info",
    name: "companyInfo",
    component: function() {
      return import("../views/company_info.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/tech/:id",
    name: "tech_dashboard",
    component: function() {
      return import("../views/dashboard/technician.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "tech"],
    },
  },
  {
    path: "/alarm-report/alarm/:imei",
    name: "alarm_report",
    component: function() {
      return import("../views/tech/alarm_report.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "tech", "seller", "reseller"],
    },
  },
  {
    path: "/alarm-report/search",
    name: "search_alarm",
    component: function() {
      return import("../views/tech/search_alarm.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "tech", "seller", "reseller"],
    },
  },
  {
    path: "/config/alarm",
    name: "config_alarm",
    component: function() {
      return import("../views/tech/config_alarm.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "tech", "seller", "reseller"],
    },
  },
  {
    path: "/securtool/tech",
    name: "tech_securtool",
    component: function() {
      return import("../views/securtool/technician.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "tech"],
    },
  },
  {
    path: "/addon-activate/:type?/:id?",
    name: "addonBuy",
    component: function() {
      return import("../views/addon_buy.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "alarm_center", "tech", "reseller", "seller"],
    },
  },
  {
    path: "/kunder/:type/:id",
    name: "customers",
    component: function() {
      return import("../views/customers.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "reseller", "superuser"],
    },
  },
  {
    path: "/mina-avtal/:type/:id",
    name: "customer_agreements",
    component: function() {
      return import("../views/agreements.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "reseller", "seller", "tech"],
    },
  },
  {
    path: "/t/:token(.*)",
    name: "trackAlarm",
    component: function() {
      return import("../components/gps_track_alarm.vue");
    },
    meta: {
      authRequired: false,
      roles: [],
    },
  },
  {
    path: "/admin/order-alarm-log/:id?",
    name: "admin_order_alarm_log",
    component: function() {
      return import("../views/admin_order_alarm_log.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: function() {
      return import("../views/dashboard/admin.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/kunder",
    name: "customers_admin",
    component: function() {
      return import("../views/admin/customers.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin/larm",
    name: "alarm_admin",
    component: function() {
      return import("../views/admin/alarms.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin/order-document",
    name: "order_document",
    component: function() {
      return import("../views/admin/order_document.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/users",
    name: "users",
    component: function() {
      return import("../views/admin/users.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller", "reseller", "superuser"],
    },
  },
  {
    path: "/admin/skapa-larm/:customer_id/:unit_id/:alarmId?",
    name: "create_alarm_admin",
    component: function() {
      return import("../views/admin/create/alarm.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin/skapa-verksamhet/:customer_id",
    name: "create_unit_admin",
    component: function() {
      return import("../views/admin/create/unit.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "seller", "reseller"],
    },
  },
  {
    path: "/unit/floor-plan/:customer_id/:unit_id",
    name: "unit_floor_plan",
    component: function() {
      return import("../views/unit_floor_plan.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin/skapa-kund",
    name: "create_customer_admin",
    component: function() {
      return import("../views/admin/create/customer.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller", "reseller"],
    },
  },
  {
    path: "/user/new",
    name: "create_user",
    component: function() {
      return import("../views/admin/create/user.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller", "reseller", "superuser"],
    },
  },
  {
    path: "/admin/skapa-staff",
    name: "create_staff",
    component: function() {
      return import("../views/admin/create/staff.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/staff",
    name: "staffs",
    component: function() {
      return import("../views/admin/staffs.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin/skapa-reseller",
    name: "create_reseller",
    component: function() {
      return import("../views/admin/create/reseller.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/reseller",
    name: "resellers_admin",
    component: function() {
      return import("../views/admin/resellers.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/addons",
    name: "addons",
    component: function() {
      return import("../views/admin/addons.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/addon/create",
    name: "create_addon",
    component: function() {
      return import("../views/admin/create/addon.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/larmcentraler",
    name: "alarm_central",
    component: function() {
      return import("../views/admin/alarm_centrals.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/skapa-larmcentral",
    name: "create_alarm_central",
    component: function() {
      return import("../views/admin/create/alarm_central.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/avtal",
    name: "agreements",
    component: function() {
      return import("../views/agreements.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/skapa-avtal",
    name: "create_agreement",
    component: function() {
      return import("../views/admin/create/agreement.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller"],
    },
  },
  {
    path: "/admin/alarm-reminder",
    name: "admin_alarm_reminder",
    component: function() {
      return import("../views/admin/alarm_test_reminder.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin"],
    },
  },
  {
    path: "/admin/error-logs",
    name: "errorLogs",
    component: function() {
      return import("../views/error_logs.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "seller", "tech"],
    },
  },
  {
    path: "/auto-provlarm",
    name: "autoProvlamStandalone",
    component: function() {
      return import("../views/auto-provlarm.vue");
    },
    meta: {
      authRequired: true,
      roles: ["device"]
    },
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: function() {
      return import("../views/auth/forgot_password.vue");
    },
    meta: {
      authRequired: false,
      roles: [],
    },
  },
  {
    path: "/reset-password/:id/:token",
    name: "reset_password",
    component: function() {
      return import("../views/auth/reset_password.vue");
    },
    meta: {
      authRequired: false,
      roles: [],
    },
  },
  {
    path: "/login/change-password",
    name: "change_password_first_login",
    component: function() {
      return import("../views/auth/change_password_first_login.vue");
    },
    meta: {
      authRequired: true,
      roles: ["admin", "superuser", "user", "alarm_center", "tech", "reseller", "seller", "securdesktop"],
    },
  },
  {
    path: "*",
    name: "notFound",
    component: function() {
      return import("../views/not_found.vue");
    },
    meta: {
      authRequired: false,
      roles: [],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // eslint-disable-line
  const { authRequired, roles } = to.meta;
  const currentUser = store.state.user;

  if (authRequired) {
    if (!currentUser) {
      return next({ path: "/login", query: { returnUrl: to.path } });
    }

    if (roles.length && !roles.includes(currentUser.role)) {
      return next({ path: "/" });
    }
  }

  next();
});

router.afterEach(() => {
  // eslint-disable-line
  store.commit("SET_SIDEBAR_OPEN", false);
  store.commit("SET_PAGE_TITLE", "");
});

export default router;
