<template>
  <div>
    <div v-show="!showOTPVerification">
      <ValidationObserver ref="formLogin">
        <form class="flex flex-col">
          <div class="mb-8 rounded">
            <BaseInput v-model="username" type="text" :field_name="$t('login_page.username')" rules="required" />
          </div>
          <div class="mb-8 rounded">
            <BaseInput v-model="password" type="password" :field_name="$t('login_page.password')" rules="required" />
          </div>
          <div class="clearfix">
            <router-link tag="div" class="float-left pt-3 cursor-pointer text-xs font-bold text-primary-500" to="/forgot-password">{{ $t("login_page.forgot_password") }}</router-link>
            <div class="float-right">
              <button class="btn-green px-2 py-3 text-sm" @click.prevent="login()">
                <span class="mr-1">{{ $t("login_page.signin") }}</span> <BaseIcon icon="user" />
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-show="showOTPVerification">
      <ValidationObserver ref="formOTP">
        <form class="flex flex-col">
          <div class="mb-5 xl:mb-8">
            <BaseInput v-model="otp" type="text" :field_name="$t('OTP_page.two_way_verification')" rules="required" v-on:keydown.enter.prevent />
            <span class="text-sm">{{ $t("OTP_page.message_1") }}</span>
          </div>
          <div class="xl:hidden text-sm mb-8">
            <span class="link" @click.prevent="login(true)">{{ $t("OTP_page.send_new_code") }}<BaseIcon icon="redo" class="ml-1"/></span>
          </div>
          <div class="clearfix">
            <button class="float-left hidden xl:block btn-secondary-outline px-2 py-3 text-sm" @click.prevent="login(true)">
              {{ $t("OTP_page.send_new_code") }}
              <BaseIcon icon="redo" class="ml-1" />
            </button>
            <button class="float-right btn-blue px-2 py-3 text-sm" @click.prevent="login()">
              {{ $t("OTP_page.verify_login") }}
              <BaseIcon icon="shield-alt" class="ml-1" />
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      showOTPVerification: false,
      username: "",
      password: "",
      otp: "",
      fingerprint: null,
    };
  },

  methods: {
    login(resendOTP = false) {
      this.$refs.formLogin.validate().then((success) => {
        if (!success) {
          return;
        }

        // if user click resend otp to reset otp field and generate new otp
        if (resendOTP) this.otp = "";

        let data = {
          username: this.username.toLowerCase(),
          password: this.password,
          otp: this.otp,
          fingerprint: this.fingerprint,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/login`, data)
          .then((response) => {
            if (response.data.error && response.data.errorId == "wrong_otp") this.handleError(null, "Verifieringskoden är ogiltig eller utgången", "top-center");
            else if (!response.data.verified) {
              this.$refs.formOTP.reset();
              this.showOTPVerification = true;

              // show message if user try to resend otp
              if (resendOTP) this.handleSuccess("Verifieringskoden som skickats till din e-post", "top-center");
            } else if (response.data.verified) {
              this.storeAndNavigateUser(response);
              this.resetForm();
            }
          })
          .catch((error) => {
            let error_message = "Användarnamnet eller lösenordet är felaktigt, försök igen";
            if (error.response && error.response.status == 429) error_message = "För många inloggningsförsök, försök efter en stund";

            this.handleError(error, error_message, "top-center");
          });
      });
    },

    storeAndNavigateUser(response) {
      let user = response.data.data;
      let firstLogin = user.firstLogin;

      this.$store.dispatch("login", {
        token: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userObj: {
          firstname: user.firstname,
          lastname: user.lastname,
          username: user.username,
          role: user.role,
          customers_id: user.customers_id,
          units_id: user.units_id,
          alarm_center_id: user.alarm_center_id,
          reseller_id: user.reseller_id,
          seller_id: user.seller_id,
          tech_id: user.tech_id,
          reseller_type: user.reseller_type,
          reseller_alarm_center: user.reseller_alarm_center,
          reseller_special_access: user.reseller_special_access,
        },
        addons: user.addons,
      });

      this.axios.defaults.headers.common["X-Access-Token"] = response.data.accessToken;

      // navigate user to proper page
      if (firstLogin) {
        this.$router.push({ name: "change_password_first_login" });
      } else if (user.role == "superuser") {
        this.$router.push({ name: "dashboard", params: { id: "-" } });
      } else if (user.role == "user") {
        this.$router.push({ name: "dashboard", params: { id: user.customers_id.length > 0 ? user.customers_id[0] : "-" } });
      } else if (user.role == "securdesktop") {
        this.$router.push({ name: "securdesktop", params: { id: user.customers_id.length > 0 ? user.customers_id[0] : "-" } });
      } else if (user.role == "tech") {
        this.$router.push({ name: "tech_dashboard", params: { id: user.tech_id } });
      } else if (user.role == "alarm_center") {
        this.$router.push({ name: "alarm_center_dashboard", params: { id: user.alarm_center_id } });
      } else if (user.role == "reseller") {
        this.$router.push({ name: "reseller_dashboard", params: { id: user.reseller_id } });
      } else if (user.role == "seller") {
        this.$router.push({ name: "seller_dashboard", params: { id: user.seller_id } });
      } else if (user.role == "admin") {
        this.$router.push("/admin").catch(() => {});
      }
    },

    resetForm() {
      this.username = "";
      this.password = "";
      this.otp = "";
      this.$refs.formLogin.reset();
      this.$refs.formOTP.reset();
    },

    async getFingerprint() {
      const fp = await this.fpPromise;
      const result = await fp.get();
      // eslint-disable-next-line
      const { screenFrame, ...components } = result.components;
      const visitorId = this.FingerprintJS.hashComponents(components);

      this.fingerprint = {
        visitorId: visitorId,
        browser: result.components.vendorFlavors.value[0],
        platform: result.components.platform.value,
        userAgent: navigator.userAgent,
      };
    },
  },

  created() {
    this.getFingerprint();
  },
};
</script>
